<template>
  <v-app-bar
    id="app-bar"
    fixed
    app
    color="primary"
    dark
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="font-weight-light"
      v-text="$t('app.name')"
      v-if="showNameOnAppBar"
      @click="goToHome"
    />

    <v-spacer />

    <!--<v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>-->

    <profile-bar />

    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="onLogout"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
      ProfileBar: () => import('./ProfileBar'),
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
    }),

    computed: {
      ...mapState(['drawer']),
      showNameOnAppBar () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return true
        } else {
          return this.value
        }
      }
    },

    mounted () {
      EventBus.$on('logout', () => {
        this.onLogout()
      })
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
        setLogin: 'SET_LOGGED_IN',
        setLang: 'SET_LANG',
        setUser: 'SET_USER',
        setToken: 'SET_TOKEN',
      }),
      onLogout () {
        this.setLogin(false)
        this.setUser(false)
        this.setToken(false)

        this.$router.replace({
          path: '/',
        })
      },
      goToHome() {
        this.$router.push({
          name: 'Dashboard',
        })
      },
    },
  }
</script>
